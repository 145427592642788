// LandingHeader.js

import React from 'react';
import Slider from 'react-slick';
import '../css/landing.css';
import logoGreen from '../images/imgLanding/Frame 4-2.svg';
import logo from '../images/imgLanding/Frame 4-3.svg';
import login from '../images/login.svg';
import loginGreen from '../images/imgLanding/Frame 7.svg';
import before from '../images/__before.svg';
import landingPhoto1 from '../images/landingPhoto1.png';
import landingPhoto2 from '../images/imgLanding/Frame 22-2.png';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useEffect } from 'react';
import { useState } from 'react';
// import edu from "../images/p.png"
import edu from "../images/imgLanding/p-3.svg"

import { animateScroll as scroll } from 'react-scroll';

import rightImage from "../images/blok_brandowy.png.png"

import rightImage2 from "../images/imgLanding/blok_brandowy.png-3.png"
import leftImage from "../images/blok_brandowy.png-2.png"
import leftImage2 from "../images/imgLanding/blok_brandowy.png-4.png"
import groupsImage from "../images/imgLanding/p-2.png"
import userIcon from "../images/imgLanding/p-3.png"
import oficce from "../images/imgLanding/p-4.png"
import { useInView } from 'react-intersection-observer';
import map from "../images/imgLanding/image0.png"

import bgContacts from "../images/imgLanding/bgContacts.png"
import contactsGirl from "../images/imgLanding/long-haired-girl-watching-through-binoculars (2) 1.png"

import line from "../images/imgLanding/6.png"


import checkMark from "../images/imgLanding/check-mark 1.png"
import CloseIcon from '@mui/icons-material/Close';
import logoBottom from "../images/imgLanding/Frame 3.svg"
import book from "../images/imgLanding/book.svg"

import element1Price from "../images/imgLanding/Group 239.png"
import element2Price from "../images/imgLanding/Group 241.png"
import element3Price from "../images/imgLanding/Group 242-2.png"

import boyPrice from "../images/imgLanding/Group 240.png"
import girlPrice from "../images/imgLanding/Group 242.png"

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

import "../css/media/media1470.css"
import "../css/media/media1450.css"
import "../css/media/media1400.css"
import "../css/media/media1350.css"
import "../css/media/media1300.css"
import "../css/media/media1250.css"
import "../css/media/media1200.css"
import "../css/media/media1150.css"
import "../css/media/media1023.css"
import "../css/media/media767.css"
import "../css/media/mediaMobile.css"
import "../css/media/mediaIphone.css"


// ! images
import im1 from "../images/imgLanding/Frame 13-2.png"
import im2 from "../images/imgLanding/Frame 13.png"
import im3 from "../images/imgLanding/Frame 16-2.png"
import im4 from  "../images/imgLanding/Frame 16-3.png"
import im5 from "../images/imgLanding/Frame 17-2.png"
import im6 from "../images/imgLanding/Frame 17.png"

import zalety from "../images/imgLanding/zalety.png"
import girl from "../images/imgLanding/Frame 25.png"

import wcrotce from "../images/imgLanding/Frame 29.svg"
import { Link } from 'react-router-dom';
import { store } from '../store/store';
import { actionLead } from '../actions/actions';

const TikTokIcon = ({ color = "#000000" }) => {
  return (
    <svg
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      width="100%"
      height="100%"
    >
      <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
    </svg>
  );
};



const data = [
  {
    id: 1,
    title: "Kurs",
    language: "Angielski",
    programa: "",
    image: im2
  },
  {
    id: 2,
    title: "Kurs",
    language: "Hiszpański",
    image: im3,
    wcr: wcrotce,
  },
  {
    id: 3,
    title: "Kurs",
    language: "Polski",
    image: im1,
    wcr: wcrotce,
  },
  {
    id: 4, 
    title: "Kurs" ,
    language: "Francuski",
    image: im4,
    wcr: wcrotce,
  },
  {
    id: 5, 
    title: "Kurs" ,
    language: "Niemiecki",
    image: im5,
    wcr: wcrotce,
  },
  {
    id: 6, 
    title: "Kurs" ,
    language: "Rosyjski",
    image: im6,
    wcr: wcrotce,
  }
]

export const Bottom = () => {
  const scrollToAbout = () => {
    scroll.scrollTo(document.getElementById('about').offsetTop, {
      duration: 500,
      smooth: 'easeInOutQuart',
    });
  };

  const scrollToKursy = () => {
    scroll.scrollTo(document.getElementById('study').offsetTop, {
      duration: 500,
      smooth: 'easeInOutQuart',
    });
  };

  const scrollToCennik = () => {
    scroll.scrollTo(document.getElementById('cennik').offsetTop, {
      duration: 500,
      smooth: 'easeInOutQuart',
    });
  };

  const scrollToContact = () => {
    scroll.scrollTo(document.getElementById('contacts').offsetTop, {
      duration: 500,
      smooth: 'easeInOutQuart',
    });
  };

  const scrollToMain = () => {
    scroll.scrollTo(document.getElementById('main').offsetTop, {
      duration: 500,
      smooth: 'easeInOutQuart',
    });
  }

  const scrollToLang  = () => {
    scroll.scrollTo(document.getElementById('lang').offsetTop, {
      duration: 500,
      smooth: 'easeInOutQuart',
    });
  }


  return (

    <div className='landing_bottom'>
        <div className='landing_bottom_left'>
          <div>
              <img src={logoBottom} alt/>
              <span>Przyjmij moc języka i odblokuj świat możliwości. Poszerzaj swoje horyzonty, nawiązuj kontakty z różnymi kulturami i twórz nowe szanse. W Międzynarodowej Szkole Językowej jesteśmy tu, aby prowadzić Cię w Twojej nauce języków.</span>
          </div>
          <div>
              <h4>Przydatne linki</h4>
              <ul>
                <li><a onClick={scrollToMain}>Strona glówna</a></li>
                <li><a onClick={scrollToAbout}>O szkole</a></li>
                <li><a onClick={scrollToKursy}>Kursy</a></li>
                <li><a onClick={scrollToLang}>Języki</a></li>
                <li><a onClick={scrollToCennik}>Cennik</a></li>
                <li><a onClick={scrollToContact}>Kontakt</a></li>
              </ul>
          </div>
        </div>
        <div className='landing_bottom_right'>
            <div>
              <h4>Contact Info</h4>
              <span><strong>E-mail:</strong>biuro@iifl-institute.com</span>
              <span><strong>Adres:</strong>19 Kofi Annan Street Accra, Ghana</span>
            </div>
            <div>
              <h4>International Institute of Foreign Languages</h4>
            </div>
        </div>
    </div>
  )
}

export const Messages = () => {
  return (
    <div className='marketing' style={{display: "flex",flexDirection: "column", justifyContent: "right" , alignItems: "flex-start" , cursor: "pointer" , bottom: "140px" , right: "20px" , position: "absolute" , zIndex:20}}>
      <a style={{cursor: "pointer"}} href="https://www.facebook.com/instituteiifl"> 
          <FacebookIcon  sx={{ color: 'blue', fontSize: 40 }} />
      </a>
      <a  style={{cursor: "pointer"}} href="https://www.instagram.com/iifl_international_institute">

          <InstagramIcon sx={{color: "red" , fontSize: 40}}/>
      </a>
      <a style={{ width: "40px" }} href="https://www.tiktok.com/@iifl_2024?_t=8kOPCQcYtVO&_r=1">
          <TikTokIcon />
      </a>
    </div>
  )
}

export const Contacts = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return(
    <div className='contacts' id='contacts'>
          {
            windowWidth < 1026 ? (
              <>
                  <img src={bgContacts} alt="girl"/>
                  <div className='contacts_left'>
                      <h1>Skontaktuj się <strong>z nami!</strong></h1>
                      {/* <p>Zachęcamy do kontaktu, aby dowiedzieć się więcej o tym, jak możemy dostosować nasze usługi do Twoich potrzeb. Skontaktuj się z nami telefonicznie lub drogą mailową. Nasz zespół chętnie pomoże Ci znaleźć najlepsze rozwiązanie.</p> */}
                      <span>Czekamy na Ciebie!</span>
                      <img src={contactsGirl} alt/>
                  </div>
                  <div className='contacts_right'>
                      {/* <h4>Kontakt</h4> */}
                      <span><strong>E-mail:</strong>biuro@iifl-institute.com</span>
                      <span><strong>Adres:</strong>19 Kofi Annan Street Accra, Ghana</span>
                  </div>
                  <Messages/>
              </>
            ) : 
            (
              <>              
                <img src={bgContacts} alt="girl"/>
                <div className='contacts_left'>
                      <h1>Skontaktuj się <strong>z nami!</strong></h1>
                      <p>Zachęcamy do kontaktu, aby dowiedzieć się więcej o tym, jak możemy dostosować nasze usługi do Twoich potrzeb. Skontaktuj się z nami telefonicznie lub drogą mailową. Nasz zespół chętnie pomoże Ci znaleźć najlepsze rozwiązanie.</p>
                      <span>Czekamy na Ciebie!</span>
                      <img src={contactsGirl} alt/>
                </div>
                <div className='contacts_right'>
                      <h4>Kontakt</h4>
                      <span><strong>E-mail:</strong>biuro@iifl-institute.com</span>
                      <span><strong>Adres:</strong>19 Kofi Annan Street Accra, Ghana</span>
                </div>
                <Messages/>
              </>
            )
          }
    </div>
  )
}


export const Map = () => {
  return(
    <div className='map'>
      <img src={map} alt="map"/>
      <div className='map_item'>
            <div>
              <span>10M</span>
              <p>Learners & counting</p>
            </div>
            <div>
              <span>38K</span>
              <p>Total courses</p>
            </div>
            <div>
              <span>4+</span>
              <p>Languages</p>
            </div>
            <div>
              <span>27</span>
              <p>Successful students</p>
            </div>
      </div>
    </div>
  )
}


export const Price = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [openModal , setOpenModal] = useState(false)

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const scrollToMain = () => {
    // scroll.scrollTo(document.getElementById('main').offsetTop, {
    //   duration: 500,
    //   smooth: 'easeInOutQuart',
    // });
    setOpenModal(true)
  }


  return (
    <>
    
      <div className='price' id='cennik'>
        {
          windowWidth < 1026 ? 
          (
            <>
            <span>Ceny</span>
            <div className='price_left'>
                <div className='price_left_line'>
                    <img src={element1Price} alt/>
                    <span>Dostarczamy usługi edukacyjne na najwyższym poziomie, dbając jednocześnie o atrakcyjne ceny dostosowane do różnych potrzeb.</span>
                </div>
                <div className='price_left_line'>
                    <img src={element2Price} alt/>
                    <span>Nasze pakiety cenowe obejmują indywidualne kursy, szkolenia korporacyjne oraz zajęcia grupowe. Zapewniamy przejrzystość i uczciwość w kwestii kosztów, a także oferujemy różne formy płatności.</span>
                </div>
                <div className='price_left_line'>
                    <img src={element3Price} alt/>
                    <span>Wierzymy, że nauka języków nie powinna być luksusem, dlatego staramy się dostosować nasze ceny do różnych budżetów, zapewniając jednocześnie najwyższą jakość usług.</span>
                </div>
              </div>
              <div className='price_right'>
                  <img src={girlPrice} alt="boy"/>
                  <img src={boyPrice} alt="boy"/>
              </div>
              <div className='price_bottom'>
                  <span>Skontaktuj się z nami, aby uzyskać szczegółowe informacje na temat cen.</span>
                  <div className='price_bottom_button'>
                    <button onClick={scrollToMain}>Skontaktuj się</button>
                  </div>
              </div>
            </>
          ) :
          (
            <>
            <span>Ceny</span>
            <div className='price_left'>
                <div className='price_left_line'>
                    <img src={element1Price} alt/>
                    <span>Dostarczamy usługi edukacyjne na najwyższym poziomie, dbając jednocześnie o atrakcyjne ceny dostosowane do różnych potrzeb.</span>
                </div>
                <div className='price_left_line'>
                    <img src={element2Price} alt/>
                    <span>Nasze pakiety cenowe obejmują indywidualne kursy, szkolenia korporacyjne oraz zajęcia grupowe. Zapewniamy przejrzystość i uczciwość w kwestii kosztów, a także oferujemy różne formy płatności.</span>
                </div>
                <div className='price_left_line'>
                    <img src={element3Price} alt/>
                    <span>Wierzymy, że nauka języków nie powinna być luksusem, dlatego staramy się dostosować nasze ceny do różnych budżetów, zapewniając jednocześnie najwyższą jakość usług.</span>
                </div>
            </div>
            <div className='price_right'>
                <img src={girlPrice} alt="boy"/>
                <img src={boyPrice} alt="boy"/>
            </div>
            <div className='price_bottom'>
                <span>Skontaktuj się z nami, aby uzyskać szczegółowe informacje na temat cen.</span>
                <div className='price_bottom_button'>
                  <button onClick={scrollToMain}>Skontaktuj się</button>
                </div>
            </div>
            </>
          )
        }
      </div>
      {
        openModal && <ContactModal  setOpenContactModal={setOpenModal}/>
      }
    </>
  )
}


export const Cennik = () => {
  return(
    <div className='cennik' id='lang'>
        <span>Języki</span>
        <img src={line} alt className='cennik_bottom'/>
        <p>Oferujemy naukę wielu języków, w tym:</p>
          <div className='cennik_wrapper'>
              {
                data?.map((item) => {
                  return <div className='cennik_item' key={item.id}>
                    <img src={item.image} alt="ccc"/>
                    <div className='cennik_price'>
                        <span>{item.language}<img src={item?.wcr} alt=""/></span>
                    </div>
                    <div className='hover-paragraph'>
                        <div className='hover_paragraph_content'>
                              <span>{item.title}</span>
                              <strong>{item.language}</strong>
                              <label>Program kursu :</label>
                              <ul>
                                <li>Lekcja z lektorem</li>
                                <li>Zajęcia online</li>
                                <li>Praktyka</li>
                                <li>Indywidualne zajęcia</li>
                              </ul>
                        </div>
                    </div>
                  </div>
                })
              }
        </div>
    </div>
  )
}


export const NashiZalety = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div className='zalety'>
      {
        windowWidth < 1026 ? (
          <>
        <div className='zalety_right'>
            <h4>Nasze zalety</h4>
            <div className='zalety_left'>
                <img src={girl} alt=''/>
            </div>
            <div className='zalety_abzac'>
              <img src={checkMark} alt/>
              <span>Wszystkie nasze kursy odbywają się online przy użyciu nowoczesnych narzędzi komunikacyjnych, co umożliwia interaktywną i efektywną naukę. </span>
            </div>
            <div className='zalety_abzac'>
              <img src={checkMark} alt/>
              <span>Bez względu na wybrany format, nasz zespół nauczycieli zawsze jest gotowy dostosować programy do indywidualnych potrzeb uczniów, zapewniając jednocześnie wysoką jakość nauczania.</span>
            </div>
        </div>   
          </>
        ) : (
          <>
        <img src={zalety} alt=""/>
        <div className='zalety_left'>
            <img src={girl} alt=''/>
        </div>
        <div className='zalety_right'>
            <h4>Nasze zalety</h4>
            <div className='zalety_abzac'>
              <img src={checkMark} alt/>
              <span>Wszystkie nasze kursy odbywają się online przy użyciu nowoczesnych narzędzi komunikacyjnych, co umożliwia interaktywną i efektywną naukę. </span>
            </div>
            <div className='zalety_abzac'>
              <img src={checkMark} alt/>
              <span>Bez względu na wybrany format, nasz zespół nauczycieli zawsze jest gotowy dostosować programy do indywidualnych potrzeb uczniów, zapewniając jednocześnie wysoką jakość nauczania.</span>
            </div>
        </div>   
          </>
        )
      }
    </div>
  )
}

export const Nauka = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [refLeftItem1, inViewLeftItem1] = useInView({
    triggerOnce: false,
  });

  const [refRightItem1, inViewRightItem1] = useInView({
    triggerOnce: false,
  });

  const [refLeftItem2, inViewLeftItem2] = useInView({
    triggerOnce: false,
  });

  const [refRightItem2, inViewRightItem2] = useInView({
    triggerOnce: false,
  });

  const [refLeftItem3, inViewLeftItem3] = useInView({
    triggerOnce: false,
  });

  const [refRightItem3, inViewRightItem3] = useInView({
    triggerOnce: false,
  });

  const [refLeftItem4, inViewLeftItem4] = useInView({
    triggerOnce: false,
  });

  const [refRightItem4, inViewRightItem4] = useInView({
    triggerOnce: false,
  });

  return (
    <div className='nauka' id='study'>
      <h1>Kursy</h1>

      {
        windowWidth < 1026 ? 
        (
          <>
            <div className='nauka_blok'>
                <div className="left_item">
                  <img src={edu} alt="edu" style={{ width: 120, height: 120, marginTop: 100 }} />
                  <span>Kursy Indywidualne<br/>dla pracowników</span>
                </div>
                <div className='middle_item'>
                  <span>    Dla pracowników, którzy preferują indywidualne podejście, proponujemy kursy dostosowane do ich indywidualnych potrzeb. Indywidualne lekcje pozwalają na skoncentrowanie się na konkretnych obszarach językowych, co przyspiesza proces nauki.</span>
                </div>
            </div>
            <div className='nauka_blok'>
                <div className={`blok_right2 ${inViewRightItem2 ? 'animate' : ''}`} ref={refRightItem2}>
                  <img src={groupsImage} alt="edu" style={{ width: 120, height: 120, marginTop: 100 }} />
                  <span style={{paddingTop: "20px"}}>Zajęcia Grupowe</span>
                </div>
                <div className='middle_item'>
                  <span>Dla osób ceniących interakcję i atmosferę grupową proponujemy zajęcia w niewielkich grupach. To doskonała okazja do praktykowania języka w codziennych sytuacjach oraz dzielenia się doświadczeniem z innymi uczestnikami. Zajęcia grupowe dostępne są zarówno stacjonarnie, jak i online, zapewniając uczestnikom możliwość nauki w komfortowym dla nich środowisku.</span>
                </div>
             </div>
             <div className='nauka_blok'>
                <div className="left_item">
                  <img src={userIcon} alt="edu" style={{  width: 120, height: 120, marginTop: 100 }} />
                  <span style={{marginTop: 20}}>Kursy Językowe Indywidualne</span>
                </div>
                <div className='middle_item'>
                  <span>    Nasi doświadczeni nauczyciele oferują indywidualne podejście do nauki, dostosowując programy do Twoich celów i tempa. Bez względu na poziom zaawansowania, gwarantujemy skuteczną naukę i wsparcie w pokonywaniu trudności. W ramach dostosowania do współczesnych trendów, oferujemy także indywidualne kursy online, umożliwiające elastyczność i dostęp do nauki z dowolnego miejsca na świecie.</span>
                </div>
             </div>
             <div className='nauka_blok'>
                  <div className={`blok_right2 ${inViewRightItem4 ? 'animate' : ''}`} ref={refRightItem4}>
                    <img src={oficce} alt="edu" style={{  width: 120, height: 120, marginTop: 100 }} />
                    <span style={{paddingTop: "20px"}}>Kursy Dla Firm</span>
                  </div>
                  <div className='middle_item'>
                    <span>Dla przedsiębiorstw stawiających na rozwój globalny proponujemy spersonalizowane programy szkoleniowe. Nasze szkolenia korporacyjne obejmują komunikację biznesową, tłumaczenia oraz umiejętności interpersonalne w międzynarodowym środowisku pracy. Zarówno tradycyjne zajęcia stacjonarne, jak i szkolenia online są dostępne, aby dostosować się do potrzeb naszych klientów.</span>
                  </div>
             </div>
                
          </>
        )
      :
      (
          <>
       <div className='nauka_blok'>
          <div className={`left_item ${inViewLeftItem1 ? 'animate' : ''}`} ref={refLeftItem1}>
            <img src={edu} alt="edu" style={{ width: 120, height: 120, marginTop: 100 }} />
            <span>Kursy Indywidualne<br/>dla pracowników</span>
          </div>
          <div className='middle_item'>
            <span>    Dla pracowników, którzy preferują indywidualne podejście, proponujemy kursy dostosowane do ich indywidualnych potrzeb. Indywidualne lekcje pozwalają na skoncentrowanie się na konkretnych obszarach językowych, co przyspiesza proces nauki.</span>
          </div>
          <div className={`blok_right ${inViewRightItem1 ? 'animate' : ''}`} ref={refRightItem1}>
            <img src={rightImage} alt="Right Image" />
          </div>
      </div>
      <div className='nauka_blok'>
          <div className={`left_item2 ${inViewLeftItem2 ? 'animate' : ''}`} ref={refLeftItem2}>
            <img src={leftImage} alt="edu" style={{ width: 370, height: 400  }} />
          </div>
          <div className='middle_item'>
            <span>Dla osób ceniących interakcję i atmosferę grupową proponujemy zajęcia w niewielkich grupach. To doskonała okazja do praktykowania języka w codziennych sytuacjach oraz dzielenia się doświadczeniem z innymi uczestnikami. Zajęcia grupowe dostępne są zarówno stacjonarnie, jak i online, zapewniając uczestnikom możliwość nauki w komfortowym dla nich środowisku.</span>
          </div>
          <div className={`blok_right2 ${inViewRightItem2 ? 'animate' : ''}`} ref={refRightItem2}>
            <img src={groupsImage} alt="edu" style={{ width: 120, height: 120, marginTop: 100 }} />
            <span style={{paddingTop: "20px"}}>Zajęcia Grupowe</span>
          </div>
      </div>
      <div className='nauka_blok'>
          <div className={`left_item ${inViewLeftItem3 ? 'animate' : ''}`} ref={refLeftItem3}>
            <img src={userIcon} alt="edu" style={{  width: 120, height: 120, marginTop: 100 }} />
            <span style={{marginTop: 20}}>Kursy Językowe Indywidualne</span>
          </div>
          <div className='middle_item'>
            <span>    Nasi doświadczeni nauczyciele oferują indywidualne podejście do nauki, dostosowując programy do Twoich celów i tempa. Bez względu na poziom zaawansowania, gwarantujemy skuteczną naukę i wsparcie w pokonywaniu trudności. W ramach dostosowania do współczesnych trendów, oferujemy także indywidualne kursy online, umożliwiające elastyczność i dostęp do nauki z dowolnego miejsca na świecie.</span>
          </div>
          <div className={`blok_right ${inViewRightItem3 ? 'animate' : ''}`} ref={refRightItem3}>
            <img src={rightImage2} alt="Right Image" />
          </div>
      </div>
      <div className='nauka_blok'>
          <div className={`left_item2 ${inViewLeftItem4 ? 'animate' : ''}`} ref={refLeftItem4}>
            <img src={leftImage2} alt="edu" style={{ width: 370, height: 400 }} />
          </div>
          <div className='middle_item'>
            <span>Dla przedsiębiorstw stawiających na rozwój globalny proponujemy spersonalizowane programy szkoleniowe. Nasze szkolenia korporacyjne obejmują komunikację biznesową, tłumaczenia oraz umiejętności interpersonalne w międzynarodowym środowisku pracy. Zarówno tradycyjne zajęcia stacjonarne, jak i szkolenia online są dostępne, aby dostosować się do potrzeb naszych klientów.</span>
          </div>
          <div className={`blok_right2 ${inViewRightItem4 ? 'animate' : ''}`} ref={refRightItem4}>
            <img src={oficce} alt="edu" style={{  width: 120, height: 120, marginTop: 100 }} />
            <span style={{paddingTop: "20px"}}>Kursy Dla Firm</span>
          </div>
      </div>
          </>
      )
      
      }
     
    </div>
  );
};

// !сделать новое в случае если экран телефона маленький то выводить по другому 



export const SchoolComponent = () => {
  return (
    <div className='oSchool' id='about'>
        <h1>O szkole</h1>
        <div className='oSchool_wrapper'>
            <div className='oSchool_wrapper_left'>
              <img src={book} alt="book"/>
            </div>
            <div className='oSchool_wrapper_right'>
              <span><strong>International Institute of Foreign Languages</strong> to miejsce, gdzie pasja do nauki języków spotyka się z profesjonalizmem. Dostarczamy wysokiej jakości usługi edukacyjne zarówno klientom biznesowym, poszukującym skutecznych narzędzi komunikacji, jak i indywidualnym entuzjastom języków. Nasz zespół składa się z doświadczonych nauczycieli, którzy nie tylko posiadają bogatą wiedzę, ale także pasję do dzielenia się nią z innymi.</span><br/>
              <span><strong>Nasza misja to nie tylko nauczanie gramatyki,</strong> ale przede wszystkim rozwijanie praktycznych umiejętności komunikacyjnych. Dzięki innowacyjnym metodom nauczania i zastosowaniu nowoczesnych technologii, stwarzamy inspirujące środowisko do nauki języków obcych.</span><br/>
              <span>Dołączając do International Institute of Foreign Languages, stajesz się częścią społeczności, gdzie <strong>nauka to nie tylko obowiązek,</strong>ale także przyjemność i okazja do odkrywania nowych horyzontów. </span><br/>
            </div>
        </div>
    </div>
  )
}


const ContactModal = ({ setOpenContactModal }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    text: '',
  });

  const [successMessage, setSuccessMessage] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    store.dispatch(actionLead({name: formData?.name , email: formData?.email , phone: formData?.phone , text: formData?.text}))
    setSuccessMessage('Dziękujemy, wkrótce się z Tobą skontaktujemy!');
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  return (
    <div className={`contact-modal-overlay show`}>
      <div className='contact_modal'>
        <div className='modal_content'>
          <CloseIcon onClick={() => setOpenContactModal(false)} className='close_button' />
          <h2>Skontaktuj się z nami</h2>
          {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
          <form onSubmit={handleSubmit}>
            {/* Форма остается без изменений */}
            <div className='form-group'>
              <label htmlFor='name'>Name</label>
              <input type='text' id='name' name='name' value={formData.name} onChange={handleChange} />
            </div>
            <div className='form-group'>
              <label htmlFor='email'>Email</label>
              <input type='email' id='email' name='email' value={formData.email} onChange={handleChange} />
            </div>
            <div className='form-group'>
              <label htmlFor='phone'>Phone</label>
              <input type='tel' id='phone' name='phone' value={formData.phone} onChange={handleChange} />
            </div>
            <div className='form-group'>
              <label htmlFor='text'>Text</label>
              <textarea id='text' name='text' value={formData.text} onChange={handleChange} />
            </div>
            <button type='submit'>Wyślij</button>
          </form>
        </div>
      </div>
    </div>
  );
};


const LandingHeader = () => {
  const [scrolled, setScrolled] = useState(false);

  const [isMenuOpen, setMenuOpen] = useState(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [openContactModal , setOpenContactModal] = useState(false)

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth > 1026) {
      setMenuOpen(true);
    }
  }, [windowWidth]);

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const burgerBars = document.querySelectorAll('.burger-button .bar');

    if (scrollPosition > 2) {
        setScrolled(true);
        burgerBars.forEach(bar => {
            bar.classList.add('active');
        });
    } else {
        setScrolled(false);
        burgerBars.forEach(bar => {
            bar.classList.remove('active');
        });
    }
};

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToAbout = () => {
    scroll.scrollTo(document.getElementById('about').offsetTop, {
      duration: 500,
      smooth: 'easeInOutQuart',
    });
    setMenuOpen(false);
  };

  const scrollToKursy = () => {
    scroll.scrollTo(document.getElementById('study').offsetTop, {
      duration: 500,
      smooth: 'easeInOutQuart',
    });
    setMenuOpen(false);
  };

  const scrollToCennik = () => {
    scroll.scrollTo(document.getElementById('cennik').offsetTop, {
      duration: 500,
      smooth: 'easeInOutQuart',
    });
    setMenuOpen(false);
  };

  const scrollToContact = () => {
    scroll.scrollTo(document.getElementById('contacts').offsetTop, {
      duration: 500,
      smooth: 'easeInOutQuart',
    });
    setMenuOpen(false);
  };

  const scrollToMain = () => {
    scroll.scrollTo(document.getElementById('main').offsetTop, {
      duration: 500,
      smooth: 'easeInOutQuart',
    });
    setMenuOpen(false);
  }

  const scrollToLang  = () => {
    scroll.scrollTo(document.getElementById('lang').offsetTop, {
      duration: 500,
      smooth: 'easeInOutQuart',
    });
    setMenuOpen(false);
  }

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
  };

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };


  return (
  <>
  
    <div className='top-content' id='main'>
      <Slider {...sliderSettings}>
        {/* Add your slider items here */}
        <div className="slider-item">
          <img src={landingPhoto1} alt="Slider 1" />
        </div>
        <div className="slider-item">
          <img src={landingPhoto2} alt="Slider 2" />
        </div>
        {/* Add more slider items as needed */}
      </Slider>
      <div className='slider-h1'>
        <h1>Witamy w <br />International Institute<br /> of Foreign Languages</h1>
        <p>Odblokuj potencjał języków i odkryj świat możliwości.</p>
        <div className='slider-buttons'>
          <button onClick={() => setOpenContactModal(true)}>Dolącz do nas</button>
          <button onClick={scrollToLang}>Zobacz kursy</button>
        </div>
      </div>
      <header className={`page-header ${scrolled ? 'fixed-header' : 'header-content'}`}>
        <div className="logo_landing">
          {
            scrolled ?  <img src={logo} alt="Logo" /> :  <img src={logoGreen} alt="Logo" />
          }
        </div>
        {
          windowWidth < 1026 ? 
        <button className={`burger-button ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </button>
        : []
          
        }
        {
          isMenuOpen && windowWidth < 1026 ? (
            <nav className={`nav-menu ${isMenuOpen ? "burger" : ""}`} style={ scrolled ? {backgroundColor: "white"} : {backgroundColor: "inherit"}}>
              <ul>
                <li><a onClick={scrollToMain} style={scrolled ? {color: "green"} : {color: "white"}}>STRONA GŁÓWNA</a></li>
                <li><a onClick={scrollToAbout} style={scrolled ? {color: "green"} : {color: "white"}}>O SZKOLE</a></li>
                <li><a onClick={scrollToKursy} style={scrolled ? {color: "green"} : {color: "white"}}>KURSY</a></li>
                <li><a onClick={scrollToLang} style={scrolled ? {color: "green"} : {color: "white"}}>JĘZYKI</a></li>
                <li><a onClick={scrollToCennik} style={scrolled ? {color: "green"} : {color: "white"}}>CENNIK</a></li>
                <li><a onClick={scrollToContact} style={scrolled ? {color: "green"} : {}}>KONTAKT</a></li>
              </ul>
            </nav>
          ) : []
        }
        {
          windowWidth > 1026 ? (
            <nav className="nav-menu">
              <ul>
                <li><a onClick={scrollToMain} style={scrolled ? {color: "green"} : {color: "white"}}>STRONA GŁÓWNA</a></li>
                <li><a onClick={scrollToAbout} style={scrolled ? {color: "green"} : {color: "white"}}>O SZKOLE</a></li>
                <li><a onClick={scrollToKursy} style={scrolled ? {color: "green"} : {color: "white"}}>KURSY</a></li>
                <li><a onClick={scrollToLang} style={scrolled ? {color: "green"} : {color: "white"}}>JĘZYKI</a></li>
                <li><a onClick={scrollToCennik} style={scrolled ? {color: "green"} : {color: "white"}}>CENNIK</a></li>
                <li><a onClick={scrollToContact} style={scrolled ? {color: "green"} : {}}>KONTAKT</a></li>
              </ul>
            </nav>
          ) : []
        }
        <Link to="/login">
          <div className="buttons">
            <img src={before} alt="Before" />
            <img src={scrolled ? loginGreen : login } alt="Login" />
          </div>
        </Link>
      </header>
    </div>
    {
      openContactModal && <ContactModal  setOpenContactModal={setOpenContactModal}/>
    }
  </>
  );
};

export default LandingHeader;

import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import "../css/header.css"
import logo from '../images/imgLanding/Frame 4-2.svg';
import out from "../images/save.svg"
import styles from "../css/header.css"

import { useLocation } from "react-router-dom";
import { store } from '../store/store';
import { actionAddGroupInfo, actionDeleteUserInfo } from '../store/actionsReducer';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';
import { useRef } from 'react';


import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const stylesBurger = {
      burgerMenu: {
        width: "22px",
        height: "2px",
        color: "white",
        backgroundColor: "green",
        marginBottom: "6px",
        zIndex: 9999
      }
    };


const Header = ({isUpsideDown}) => {
      const navigation = useNavigate()
      const location = useLocation();
      const reduxState = useSelector((state) => state.actions?.role);

      const [isMobile, setIsMobile] = useState(false);

      const [menuOpen, setMenuOpen] = useState(false);

      const menuRef = useRef(null);


      useEffect(() => {
        const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        setIsMobile(isMobileDevice);
      }, []);


    //destructuring pathname from location
    const { pathname } = location;

    //Javascript split method to get the name of the path in array
    const splitLocation = pathname.split("/");

    const handleClean = () => {
          store.dispatch(actionDeleteUserInfo())
          setMenuOpen(false);
      //     store.dispatch(actionAddGroupInfo({}))
    }

    useEffect(() => {
      // Проверяем, является ли reduxState.rp пустым объектом
      const isRpEmpty = Object.keys(reduxState?.rp || {}).length === 0;

      if (isRpEmpty) {
          navigation("/login");
      }
  }, [reduxState]);

  const toggleMenu = () => {
      setMenuOpen(!menuOpen);
    };
  

  return (
    <div className='header_wrapper'>
        <div className='logo'>
            <img src={logo} alt=''/>
        </div>
        {
  !isMobile ? (
    // If it's not a mobile device
    reduxState?.rp === "user" || reduxState?.rp === "tutor" ? (
      !isUpsideDown && (
        <div className='nav_menu'>
          <ul>
            <li className={splitLocation[1] === "calendar" ? "active" : "disactive"}>
              <Link to="/calendar">Kalendarz</Link>
            </li>
            <li className={splitLocation[2] === "profil" ? "active" : "disactive"}>
              <Link to={`/:${1}/profil`}>Profil</Link>
            </li>
          </ul>
        </div>
      )
    ) : (
      !isUpsideDown && (
        <div className='nav_menu'>
          <ul>
            <li className={splitLocation[1] === "students" ? "active" : "disactive"} onClick={handleClean}>
              <Link to="/students">Studenci</Link>
            </li>
            <li className={splitLocation[1] === "tutors" ? "active" : "disactive"} onClick={handleClean}>
              <Link to="/tutors" onClick={handleClean}>Wykładowcy</Link>
            </li>
            <li className={splitLocation[1] === "calendar" ? "active" : "disactive"}>
              <Link to="/calendar">Kalendarz</Link>
            </li>
            <li className={splitLocation[1] === "kursy" ? "active" : "disactive"}>
              <Link to="/kursy">Kursy</Link>
            </li>
          </ul>
        </div>
      )
    )
  ) : (
    // If it's a mobile device
    reduxState?.rp === "user" || reduxState?.rp === "tutor" ? (
      !isUpsideDown && (
        <div className='nav_menu'>
          <ul>
            <li className={splitLocation[1] === "calendar" ? "active" : "disactive"}>
              <Link to="/calendar">Kalendarz</Link>
            </li>
            <li className={splitLocation[2] === "profil" ? "active" : "disactive"}>
              <Link to={`/:${1}/profil`}>Profil</Link>
            </li>
          </ul>
        </div>
      )
    ) : (
      !isUpsideDown && (
            <>
               {
                     !menuOpen ? 
                        <div style={{marginTop: "130px" , position: "fixed" , right: "20px" , zIndex: 9999}} onClick={toggleMenu}>
                              <div style={stylesBurger.burgerMenu}></div>
                              <div style={stylesBurger.burgerMenu}></div>
                              <div style={stylesBurger.burgerMenu}></div>
                        </div> 
                   : []
               }
                  {menuOpen && (  <div ref={menuRef} className="slideInMenu" style={{ position: "fixed", top: "10vh", left: 0, width: "100vw", height: "calc(30vh - 6vh)", backgroundColor: "#177246" , zIndex: 9999}}>
                  <div style={{display: "flex" , flexDirection: "column" , marginTop: "0"}}>
                        <ul style={{listStyle:"none" , marginLeft: "0px", paddingLeft:"0px"}}>
                              <li className={splitLocation[1] === "students" ? "active" : "disactive"} onClick={handleClean} style={{ paddingBottom: "10px" , fontSize: "25px", width: "100%"}}>
                                    <Link to="/students">Studenci</Link>
                              </li>
                              <li className={splitLocation[1] === "tutors" ? "active" : "disactive"} onClick={handleClean} style={{ paddingBottom: "10px" , fontSize: "25px", width: "100%"}}>
                                    <Link to="/tutors" onClick={handleClean}>Wykładowcy</Link>
                              </li>
                              <li className={splitLocation[1] === "calendar" ? "active" : "disactive"} style={{ paddingBottom: "10px" , fontSize: "25px", width: "100%"}}>
                                    <Link to="/calendar">Kalendarz</Link>
                              </li>
                              <li className={splitLocation[1] === "kursy" ? "active" : "disactive"} style={{ paddingBottom: "10px" , fontSize: "25px", width: "100%"}}>
                                    <Link to="/kursy">Kursy</Link>
                              </li>
                        </ul>
                  </div>
                  <CloseOutlinedIcon onClick={toggleMenu} sx={{
                        position: "fixed" , 
                        right: "10px",
                        top: "90px",
                        fontSize: "30px"
                  }}/>
                </div>)} 
            </>
      )
    )
  )
}
        <div className='log_out'>
            <button><img src={out}/></button>
        </div>
    </div>
  )
}

export default Header
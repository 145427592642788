import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import '../css/calendar.css';
import { isSameDay, parseISO, format } from 'date-fns';

import { ReactCalendarScheduler } from 'react-calendar-scheduler';
import { Link } from 'react-router-dom';

import Header from '../components/Header';
import Select from 'react-select';
import { store } from '../store/store';
import { actionAddEvent, actionAddIndEvent, actionAllKurses, actionAllTutors, actionAllUsers, actionGroupInfo, actionGroupWithStudentsEvents, actionGroupWithTutors } from '../actions/actions';
import { connect } from 'react-redux';
import { actionCheckRole } from '../store/actionsReducer';

import ScreenRotationIcon from '@mui/icons-material/ScreenRotation';

import DatePicker from 'react-datepicker';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';


const Modal = ({setClickedAddIndEvent, lektorzy , students}) => {
  const [tutorsOfEvent , setTutorsOfEvent] = useState('')
  const [studentOfEvent , setStudentOfEvent] = useState('')

  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());

  const [title , setTitle] = useState('')
  const [info , setInfo] = useState('')

  const closeModal = () => {
    setClickedAddIndEvent(false);
  };

  const handleChangeTutor = (event) => {
      setTutorsOfEvent(event);
  };

  const handleChangeStudent = (event) => {
      setStudentOfEvent(event);
  };

  const handleCreateIndEvent = (tutorsOfEvent , studentOfEvent , title, startTime , endTime , startDate , info) => {
    // console.log({tutorId: tutorsOfEvent , userId: studentOfEvent ,  event_name: title , start_time:format(startTime,"HH:mm") , end_time: format(endTime,"HH:mm") , date: format(startDate,'yyyy/MM/dd') , event_description: info})
    store.dispatch(actionAddIndEvent({tutorId: tutorsOfEvent , userId: studentOfEvent ,  event_name: title , start_time:format(startTime,"HH:mm") , end_time: format(endTime,"HH:mm") , date: format(startDate,'yyyy/MM/dd') , event_description: info}))
        setClickedAddIndEvent(true)
  }

  return (
    <div>
        <div className='modal-overlay'>
          <div className='modal_window'>
            <div className='modal_header'>
              <div className='search_modal'>

              </div>
              <button className='close_button' onClick={closeModal}>
                X
              </button>
            </div>
            <div className='modal_body'>
                <div className='modal_body_picker'>
                        <span>Tytuł</span>
                        <input placeholder='English lesson' onChange={(e) => setTitle(e.target.value)} value={title}/>
                </div>
                <div className='modal_body_picker'>
                        <span>Zoom link</span>
                        <input placeholder='zoom...' onChange={(e) => setInfo(e.target.value)} value={info}/>
                </div>
                <div className='modal_student'>
                    <select
                      id="tutorSelect"
                      value={tutorsOfEvent}
                      onChange={(e) => handleChangeTutor(e.target.value)}
                    >
                        <option>wybierz lektora</option>
                    {lektorzy && Array.isArray(lektorzy) && lektorzy.map((lektor) => (
                      <option key={lektor.userId} value={lektor.userId}>
                        {lektor.first_name} {lektor.last_name}
                      </option>
                    ))}
                    </select>
                </div>
                <div className='modal_student'>
                    <select
                      id="tutorSelect"
                      value={studentOfEvent}
                      onChange={(e) => handleChangeStudent(e.target.value)}
                    >
                        <option>wybierz studenta</option>
                        {students?.map((tutor) => (
                          <option key={tutor.userId} value={tutor.userId}>
                            {tutor.first_name} {tutor.last_name}
                          </option>
                        ))}
                    </select>
                </div>
                <div className='modal_student'>
                        <span>od</span>
                        <DatePicker
                            selected={startTime}
                            onChange={(date) => setStartTime(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm a"
                        />
                </div>
                <div className='modal_student'>
                        <span>do</span>
                        <DatePicker
                            selected={endTime}
                            onChange={(date) => setEndTime(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm a"
                        />
                </div>
                <div className='modal_student'>
                        <span>datę</span>
                        <DatePicker
                        showIcon
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        dateFormat="yyyy/MM/dd"
                        icon={
                          <CalendarMonthIcon/>
                        }
                        />
                </div>
                <div className='modal_student'>
                        <button onClick={() => handleCreateIndEvent(tutorsOfEvent , studentOfEvent , title , startTime, endTime , startDate , info)}>dodaj lekcję indywidualną</button>
                </div>
            </div>
          </div>
        </div>
    </div>
  );
};

const MyCalendar = ({userMail , role , courses , lektorzy , students , calendarEvents ,tutorLink , calendarEventsTutor , calendarEventsStudents}) => {

  const [events , setEvents] = useState([])

  const [checkRole , setCheckRole] = useState('')

  const [kursy , setKurses] = useState({})
  const [tutors, setTutors] = useState({})
  const [studenci , setStudenci] = useState({})

  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedLektors ,setSelectedLektors ] = useState(null);
  const [selectedStudenci ,setSelectedStudenci ] = useState(null);


  const [clickedAddIndEvent , setClickedAddIndEvent] = useState(false)

    const [checkNewStudent , setCheckNewStud] = useState(false)

    const [isUpsideDown, setIsUpsideDown] = useState(false);

    useEffect(() => {
      const handleResize = () => {
        // Проверяем высоту и ширину окна для определения ориентации
        const isUpsideDownOrientation = window.innerHeight > window.innerWidth;
  
        // Обновляем состояние
        setIsUpsideDown(isUpsideDownOrientation);
  
        // Выводим в консоль true, если ориентация перевернута
        if (isUpsideDownOrientation) {
          console.log(true);
        }
      };
  
      // Добавляем обработчик события изменения размеров окна
      window.addEventListener('resize', handleResize);
  
      // Вызываем обработчик в первый раз, чтобы установить начальное значение
      handleResize();
  
      // Убираем обработчик события при размонтировании компонента
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []); 


    useEffect(() => {
      if(role){
        if(role?.role === "user"){
             setCheckRole("user")
        }
        if(role?.role === "tutor"){
            setCheckRole("tutor")
        }
        if(role?.role === "admin"){
            setCheckRole("admin")
        }
      }
  }, [role])

    // ! kursy data
    useEffect(() => {
      const fetchData = async () => {
        try {
          await store.dispatch(actionAllKurses());
          await store.dispatch(actionAllUsers());
          await store.dispatch(actionAllTutors());
        } catch (error) {
          console.log(error);
        }
      };

      fetchData();
    }, [checkNewStudent]); 

      useEffect(() => {
          setKurses(courses || []);
          setTutors(lektorzy || [])
          setStudenci(students || [])
      }, [courses , lektorzy , students]);

      const courseOptions = Array.isArray(kursy) 
      ? kursy.map(item => 
        ({ 
          value: item.group_id,
          label: item.group_name,
         })) 
        : [];

      const tutorOptions = Array.isArray(tutors)
      ? tutors.map(item => ({
          value: `${item.email}`,
          label: `${item.first_name} ${item.last_name}`
      }))
      : [];

      const studentOptions = Array.isArray(studenci)
      ? studenci.map(item => ({
          value: `${item.email}`,
          label: `${item.first_name} ${item.last_name}`
      }))
      : [];


      // description: "ыфвфывфы"

      function formatDate(inputDate) {
        const parts = inputDate.split('/');
        const formattedDate = new Date(parts[0], parts[1] - 1, parts[2]);
        
        const day = formattedDate.getDate().toString().padStart(2, '0');
        const month = (formattedDate.getMonth() + 1).toString().padStart(2, '0');
        const year = formattedDate.getFullYear().toString();
      
        return `${day}/${month}/${year}`;
      }

      useEffect(() => {
        if (calendarEvents && Array.isArray(calendarEvents)) {
          const zoomLinks = tutorLink?.map((item) => item.zoomLink);
          const newEvents = calendarEvents.map((event, index) => ({
            title: event.event_name,
            description: event.description,
            startDate: formatDate(event.date),
            endDate: formatDate(event.date),
            startTime: `${event.start_time}:00`,
            endTime: `${event.end_time}:00`,
            id: event.event_id,
            bg_color: "#FFAB91",
            element: (
              <Link
                className="link"
                // to={`${zoomLinks}`}
                onClick={(e) => {
                  e.preventDefault();
                  window.open(
                    `${zoomLinks}`,
                    "_blank"
                  );
                }}
              >
                zoom link
              </Link>
            ),
            editable: false,
            deletable: false,
            draggable: true,
            custom_class: "custom-class",
          }));
      
          setEvents(newEvents);
        }
      }, [calendarEvents]);


      useEffect(() => {
        if (calendarEventsTutor && Array.isArray(calendarEventsTutor)) {
          const zoomLinks = tutorLink?.map((item) => item.zoomLink);
          const newEvents = calendarEventsTutor.map((event, index) => ({
            title: event.event_name,
            description: event.description,
            startDate: formatDate(event.date),
            endDate: formatDate(event.date),
            startTime: `${event.start_time}:00`,
            endTime: `${event.end_time}:00`,
            id: event.event_id,
            bg_color: "#FFAB91",
            element: (
              <Link
                className="link"
                // to={`${zoomLinks}`}
                onClick={(e) => {
                  e.preventDefault();
                  window.open(
                    `${event.zoomLink}`,
                    "_blank"
                  );
                }}
              >
                zoom link
              </Link>
            ),
            editable: false,
            deletable: false,
            draggable: true,
            custom_class: "custom-class",
          }));
      
          setEvents(newEvents);
        }
      }, [calendarEventsTutor])


      useEffect(() => {
        if (calendarEventsStudents && Array.isArray(calendarEventsStudents)) {
          const newEvents = calendarEventsStudents.map((event, index) => ({
            title: event.event_name,
            description: event.description,
            startDate: formatDate(event.date),
            endDate: formatDate(event.date),
            startTime: `${event.start_time}:00`,
            endTime: `${event.end_time}:00`,
            id: event.event_id,
            bg_color: "#FFAB91",
            element: (
              <Link
                className="link"
                // to={`${zoomLinks}`}
                onClick={(e) => {
                  e.preventDefault();
                  window.open(
                    `${event.zoomLink}`,
                    "_blank"
                  );
                }}
              >
                zoom link
              </Link>
            ),
            editable: false,
            deletable: false,
            draggable: true,
            custom_class: "custom-class",
          }));
      
          setEvents(newEvents);
        }
      }, [calendarEventsStudents])
      

      useEffect(() => {
        if(checkRole === "user"){
          store.dispatch(actionGroupWithStudentsEvents(userMail))
        }
      }, [checkRole])

      useEffect(() => {
        if(checkRole === "tutor"){
          store.dispatch(actionGroupWithTutors(userMail))
        }
      }, [checkRole])

      const handleCourseChange = (selectedOption) => {
        setSelectedCourse(selectedOption);
    
        // Reset other selects
        setSelectedLektors(null);
        setSelectedStudenci(null);
        store.dispatch(actionGroupInfo(selectedOption.value))
      };
    
      const handleLektorsChange = (selectedOption) => {
        setSelectedLektors(selectedOption);
    
        // Reset other selects
        setSelectedCourse(null);
        setSelectedStudenci(null);

       store.dispatch(actionGroupWithTutors(selectedOption.value))

      };
    
      const handleStudenciChange = (selectedOption) => {
        // Handle studenci change logic
        setSelectedStudenci(selectedOption);
    
        // Reset other selects
        setSelectedCourse(null);
        setSelectedLektors(null);

        store.dispatch(actionGroupWithStudentsEvents(selectedOption.value))
      };

  
  return (
    <>
      <div className='calendar_dashboard'>
        {
          isUpsideDown ? (
            <>
            <Header isUpsideDown={isUpsideDown}/>
            <div className='blur_container'>
              <ScreenRotationIcon className='blur' style={{color: "white"}} sx={{fontSize: "150px" }}/>
            </div>
            </>
          ) : (
            <>
        <Header/>
        <div className='calendar_wrapper'>
              <div className='calendar_settings'>
                {
                  checkRole === "admin" ? (
                    <>
                    <div>
                        <h1>kursy</h1>
                        <Select options={courseOptions} isSearchable={true} value={selectedCourse} onChange={handleCourseChange} className="select_calendar"/>
                    </div>
                    <div>
                        <h1>lektorzy</h1>
                        <Select options={tutorOptions} isSearchable={true} value={selectedLektors} onChange={handleLektorsChange} className="select_calendar"/>
                    </div>
                    <div>
                        <h1>studenci</h1>
                        <Select options={studentOptions} isSearchable={true} value={selectedStudenci} onChange={handleStudenciChange} className="select_calendar" />
                    </div>
                    <div>
                        <button onClick={() => setClickedAddIndEvent(true)}>dodaj lekcję indywidualną</button>
                    </div>
                    </>
                  ) : (
                    <>
                    </>
                  )
                }
                {
                  checkRole === "tutor" ? (
                    <>
                    <div>
                        <h1>kursy</h1>
                        <Select options={courseOptions} isSearchable={true} value={selectedCourse} onChange={handleCourseChange} className="select_calendar"/>
                    </div>
                    </>
                  ) : (
                    <>
                    </>
                  )
                }
              </div>
              {
                !isUpsideDown && (
                  <ReactCalendarScheduler
                      events={events}
                      disabaleAddEventPopup={true}
                      monthCalenderMinCellHeight={120}
                      calenderHeight={720}
                      weekHourBoxHeight={50}
                      weekCalenderDayStartFromHour={8}
                      weekCalenderVisibleHour={12}
                  />
                )
              }
        </div>
            </>
          )
        }
      </div>
        {clickedAddIndEvent && (<Modal setClickedAddIndEvent={setClickedAddIndEvent} lektorzy={lektorzy} students={students}/>)}
    </>
  );
};

export const MyCalendarConnect = connect((state) => ({
  tutorLink: state.promise.groupInfo?.payload?.tutors,
  userMail: state.promise.userInfo?.payload?.email,
  role: state.promise.userInfo?.payload,
  courses: state.promise.kursy?.payload,
  lektorzy: state.promise.tutors?.payload,
  students: state.promise.users?.payload,
  calendarEvents: state.promise.groupInfo?.payload?.events,
  calendarEventsTutor: state.promise.groupsWhereTutor?.payload?.data,
  calendarEventsStudents: state.promise.groupsWhereStudent?.payload?.data,
}))(MyCalendar);


// calendarEventsStudents: state.promise.groupsWhereStudent?.payload?.data
// если админ выбирает то тогда 


// ? сделать запрос при легитымации
// ? zoom link tutora
// ? убрать группу у студента 
// ? сделать отрисовку его графика так же как у туторов 
// ! добавление ивента через календарь
// ! сделать распределение студентов  
// ! сделать восстановление и рассылку пароля через еmail
// ! добавить месседжер 
// ! адаптация под мобилку и можно отдавать
/* 
    1.Выводить все даты курса
    2.Выводить все даты учителя 
    3.Выводить все даты студента 
    4.Потом сделать распределение  = для каждого свое для админа и для студента 
*/

// ! если я студент то я вижу только календарь и профиль 
// ! если я админ то вижу все вкладки кроме вкладки профиль 
// ! если я тутор то вижу только календарь и профиль куда я могу добавлять свою инфу 




// useEffect(() => {
//     // For demonstration purposes, setting a single date
//     setDatesFromServer(["2023-12-11"]);
//     // Uncomment this block to fetch dates from the server
//     // axios.get('your-api-endpoint')
//     //   .then(response => {
//     //     setDatesFromServer(response.data.dates); // Assuming the server returns an array of date strings
//     //   })
//     //   .catch(error => {
//     //     console.error('Error fetching dates:', error);
//     //   });
//   }, []); // Empty dependency array ensures the effect runs only once
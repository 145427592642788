import React from 'react'
import "../css/menu.css"
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { useState } from 'react';
import { useEffect } from 'react';
import { store } from '../store/store'

import { actionAddUserInfo } from '../store/actionsReducer'
import { useLocation } from 'react-router-dom';

const Menu = ({ students , checkNewStudent , courses , setAddingButtonClick , isMobile }) => {
  const [studRow, setStudRow] = useState({
    name: '',
    group: 'Group 2',
  });

  const [swipe , setSwipe] = useState(false)

  const [searchTerm, setSearchTerm] = useState('');

  const location = useLocation();

  const { pathname } = location;

  const splitLocation = pathname.split("/");

  const getGroupNameByGroupId = (courses, targetGroupId) => {
    for (let i = 0; i < courses?.length; i++) {
        if (courses[i].group_id === targetGroupId) {
            return courses[i].group_name;
        }
    }
    // If no match is found
    return null;
  }

  useEffect(() => {
    if (students && students?.length > 0) {
      setStudRow((prevStudRow) => ({
        ...prevStudRow,
        name: students[0].name || '',
      }));
    }
  }, [students , checkNewStudent]);

  const handleUserInfo = (user_id, role, first_name, last_name, email, date_of_birth, nationality, start_date, id_validity_date, passport_number, field_of_study, study_mode, group , zoomLink) => {
      store.dispatch(actionAddUserInfo(user_id, role, first_name, last_name, email, date_of_birth, nationality, start_date, id_validity_date, passport_number, field_of_study, study_mode, group , zoomLink))
      setAddingButtonClick(false)
      setSwipe(false)
  }

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  
  const filteredStudents = Array.isArray(students)
  ? students.filter((item) =>
      item.first_name.toLowerCase().includes(searchTerm.toLowerCase()) &&
      item.first_name.toLowerCase() !== 'admin'
    )
  : [];


  const handleClickSwipe = () => {
    setSwipe(!swipe)
 } 


  return (
    <>
    {!isMobile ?
    
    (
      <div className='search_students_menu'>
      <div className='search_menu'>
        <input name='search' placeholder='Szukaj' value={searchTerm} onChange={handleSearchChange}/>
        <SearchOutlinedIcon className='icon_search' />
      </div>
      <div className='row_menu_wrapper'>
        <div className='column_students_wrapper'>
          <div className='column_students'>
          {filteredStudents?.length > 0 ? (
            filteredStudents?.map((item) => (
              <div key={item.userId} className='row_students' onClick={() => handleUserInfo(item.userId , item.role, item.first_name, item.last_name, item.email, item.date_of_birth, item.nationality, item.start_date, item.id_validity_date, item.passport_number, item.field_of_study, item.study_mode, item.group, item.zoomLink)}>
                <h4>{item?.first_name} {item?.last_name}</h4>
                <span>{getGroupNameByGroupId(courses ,item?.group)}</span>
              </div>
            ))
          ) : (
            <p>No matching students found</p>
          )}
          </div>
        </div>
      </div>
    </div>
    ) :
    (
      <>
    <div style={{zIndex: 9999, position: "fixed", top: "50%", right: "-10px", backgroundColor: "#177246", width: "80px",padding: "5px"  , textAlign: "center" , transform: "rotate(-90deg)" , borderRadius: "10px" , color: "white" , fontFamily: "Gilroy-Medium,sans-serif"}} onClick={() => handleClickSwipe()}> {splitLocation[1] !== "tutors" ? "Students" : "Tutors"}</div>
    <div className='search_students_menu' style={{right: swipe ? "0": "-110%" , top: "6vh" , zIndex: 9995 , backgroundColor: "white"}}>
      <div className='search_menu'>
        <input name='search' placeholder='Szukaj' value={searchTerm} onChange={handleSearchChange}/>
        <SearchOutlinedIcon className='icon_search' />
      </div>
      <div className='row_menu_wrapper'>
        <div className='column_students_wrapper'>
          <div className='column_students'>
          {filteredStudents?.length > 0 ? (
            filteredStudents?.map((item) => (
              <div key={item.userId} className='row_students' onClick={() => handleUserInfo(item.userId , item.role, item.first_name, item.last_name, item.email, item.date_of_birth, item.nationality,item.start_date, item.id_validity_date, item.passport_number, item.field_of_study, item.study_mode, item.group, item.zoomLink)}>
                <h4>{item?.first_name} {item?.last_name}</h4>
                <span>{getGroupNameByGroupId(courses ,item?.group)}</span>
              </div>
            ))
          ) : (
            <p>No matching students found</p>
          )}
          </div>
        </div>
      </div>
    </div>
      </>
    )
    }
    </>
  );
};

export default Menu;
import { store } from "./store"

function actionsReducer(state = {}, { 
    type, user_id, role, first_name, last_name, email, date_of_birth, nationality, start_date , id_validity_date, passport_number, field_of_study, study_mode, group_id , zoomLink ,  group_name , group_description ,tutors , students , events , rp
}) {
    if (type === "USER_INFO") {
        return {
            ...state,
            user_info: { user_id, role, first_name, last_name, email, date_of_birth, nationality, start_date , id_validity_date, passport_number, field_of_study, study_mode, group_id , zoomLink }
        };
    }
    if (type === "GROUP_INFO") {
        return {
            ...state,
            group_info: { group_id , group_name , group_description ,tutors , students , events}
        };
    }
    if (type === "CHECK_ROLE") {
        return {
            ...state,
            role: { rp }
        }
    }
    if (type === "DELETE_USER_INFO") {
        const { user_info, group_info, rp ,...rest } = state;
        return rest;
    }

    return state;
}

export default actionsReducer;

export const actionAddUserInfo = (user_id, role, first_name, last_name, email, date_of_birth, nationality, start_date , id_validity_date, passport_number, field_of_study, study_mode, group_id , zoomLink) => ({type: "USER_INFO" ,user_id : user_id, role : role, first_name : first_name, last_name : last_name, email: email, date_of_birth : date_of_birth, nationality: nationality,start_date: start_date , id_validity_date : id_validity_date, passport_number: passport_number, field_of_study : field_of_study, study_mode: study_mode, group_id: group_id , zoomLink: zoomLink})
export const actionAddGroupInfo = (group_id , group_name, group_description ,tutors , students , events) => ({type: "GROUP_INFO" ,group_id: group_id , group_name: group_name, group_description: group_description ,tutors: tutors, students: students , events: events})
export const actionDeleteUserInfo = () => ({
    type: "DELETE_USER_INFO"
});

export const actionCheckRole = (role) => ({type: "CHECK_ROLE", rp: role})
// export const actionCartChange = (n, id, name , price , photo) => ({ type: "CART_CHANGE", count: n, _id: id, name  , price: price , photo: photo})
// export const actionCartAdd = (n, id, name , price , photo) => ({ type: "CART_ADD", count: n, _id: id, name , price: price , photo: photo })
// export const actionUserInfo = (
//   name, 
//   phone  , 
//   adress ,
//   service_mode ,
//   products ,
//   selectedTime ,
//   deliveryPrice ,
//   comment) => ({
//     type: "USER_INFO" ,
//     user_name: name ,
//     user_phone: phone , 
//     user_adress: adress , 
//     service_mode: service_mode , 
//     products: products , 
//     selectedTime: selectedTime , 
//     deliveryPrice: deliveryPrice , 
//     comment: comment})

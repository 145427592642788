import './app.css';
import React, { createContext, useState } from 'react';
import { Provider , connect, useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Routes,
  useNavigate
} from "react-router-dom";
import { store } from './store/store';


// ! PAGES
import {LoginConnect} from "./pages/Login"
import { UserInfoConnect } from './pages/UserInfo';
import { MyCalendarConnect } from './pages/Calendar';
import { StudentsConnect } from './pages/Students';
import Profil, { PDFGenerator, ProfilConnect } from './pages/Profil';
import { LektorsConnect } from './pages/Lektors';
import { KursyConnect } from './pages/Kursy';
import Landing from './pages/Landing';
import { useEffect } from 'react';

import mammoth from 'mammoth';

import pd from './images/International Institute of Foreign Languages.docx';


const Regulaminu = () => {
        const [docContent, setDocContent] = useState(null);
      
        useEffect(() => {
          const fetchAndRenderDocx = async () => {
            try {
              const response = await fetch(pd);
              const arrayBuffer = await response.arrayBuffer();
      
              // Convert DOCX to HTML using mammoth
              const result = await mammoth.extractRawText({ arrayBuffer });
              const html = result.value;
      
              // Now you can set the HTML content in state
              setDocContent(html);
            } catch (error) {
              console.error('Error fetching or converting DOCX file:', error);
            }
          };
      
          fetchAndRenderDocx();
        }, []);
      
        if (!docContent) {
          return <div>Loading...</div>;
        }
      
        return (
          <div style={{ maxWidth: '800px', margin: '0 auto' }}>
            {docContent.split('\n').map((line, index) => (
              <p key={index} style={{ marginLeft: '20px', marginBottom: '20px' }}>{line.trim()}</p>
            ))}
          </div>
        );
      };

store.subscribe(() => console.log(store.getState()));

function App() {
        const [checkRole , setCheckRole] = useState('')

        
        const ProtectedRoute = ({ element, path }) => {
                const navigate = useNavigate();
              
                useEffect(() => {
                        const isAdmin = () => {
                                if(checkRole === "user"){
                                        return false;
                                }
                                if(checkRole === "tutor"){
                                        return false;
                                }
                                if(checkRole === "admin"){
                                        return true;
                                }
                        };
                
                        if (!isAdmin() && (path === '/students' || path === '/tutors' || path === '/kursy')) {
                        navigate('/');
                        }
                }, [navigate, path]);
                
                return element;
        };


    return (
            <div className='App'> 
                    <div>
                        <Provider store={store}>
                            <Router>
                                    <Routes>
                                            <Route path="/" element={<Landing />} exact/>
                                            <Route path="/login" element={<LoginConnect checkRole={checkRole}  setCheckRole={setCheckRole}/>} exact/>
                                            <Route path="/:userId" element={<UserInfoConnect />} />
                                            <Route path="/calendar" element={<MyCalendarConnect />} />
                                            <Route path="/students" element={<ProtectedRoute path="/students" element={<StudentsConnect />} />} />
                                            <Route path="/tutors" element={<ProtectedRoute path="/tutors" element={<LektorsConnect />} />} />
                                            <Route path="/kursy" element={<ProtectedRoute path="/kursy" element={<KursyConnect />} />} />
                                            {/* <Route path="/kursy" element={<KursyConnect />} /> */}
                                            <Route path="/:userId/profil" element={<ProfilConnect />} />
                                            <Route path="/legit" element={<PDFGenerator />} />
                                            <Route path="/regulaminu" element={<Regulaminu />} />
                                    </Routes>
                            </Router>
                        </Provider>
                    </div>
            </div>
    )
}

export default App;
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { actionAllTutors } from '../actions/actions'
import Header from '../components/Header'
import Menu from '../components/Menu'
import { StudentsDashboardConnect } from '../components/StudentDashboard'
import { store } from '../store/store'
import LockPersonIcon from '@mui/icons-material/LockPerson';

import plcHolder from "../images/undraw_teacher_re_sico 1.png"
import { useLocation } from 'react-router-dom'


export const Placeholder = ({ addingButtonClick ,setAddingButtonClick , isMobile}) => {
  const location = useLocation();
  const { pathname } = location;

  const splitLocation = pathname.split("/");

  return (
    <div className='lock'>
        <div className="placehold" style={ isMobile ? {width: "330px" , margin: "0px"} : {}}>
            <img src={plcHolder} alt="placeholder" />
            {
              splitLocation[1] === "tutors" ? (
                <div>
                  <span>Wybierz lektorza albo</span><br/>
                  <strong onClick={() => setAddingButtonClick(true)}>Dodaj nowego</strong>
                </div>
              ) : (
                splitLocation[1] === "students" ? (
                  <div>
                    <span>Wybierz studenta albo</span><br/>
                    <strong  onClick={() => setAddingButtonClick(true)}>Dodaj nowego</strong>
                  </div>
                ) : (
                  <div>
                    <span>Wybierz groupa albo</span><br/>
                    <strong>Dodaj nowego</strong>
                  </div>
                )
              )
            }
        </div>
    </div>
  )
}


const Lektors = ({tutors , deleteAction , registerAction}) => {
    const [lektors , setLektors] = useState([])

    const [checkNewLector , setCheckLektor] = useState(false)
    const reduxState = useSelector((state) => state.actions);
    const [localState, setLocalState] = useState([]);

    const [addingButtonClick , setAddingButtonClick] = useState(false)

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768); // Например, если ширина меньше или равна 768px, считаем это мобильным
      };
  
      handleResize(); // Вызываем функцию при первой загрузке компонента
  
      window.addEventListener('resize', handleResize); // Добавляем обработчик события resize
  
      return () => {
        window.removeEventListener('resize', handleResize); // Удаляем обработчик события при размонтировании компонента
      };
    }, []);

    // ! lektors data
    useEffect(() => {
        const fetchData = async () => {
            try {
              await store.dispatch(actionAllTutors());
            } catch (error) {
              console.log(error);
            }
          };
    
          fetchData();
    }, [deleteAction , registerAction])

    useEffect(() => {
        if(tutors){
            setLektors(tutors?.payload)
        }
    }, [tutors])

    useEffect(() => {
        if(reduxState.user_info){
          setLocalState(reduxState || []);
          console.log(reduxState)
        }
    }, [reduxState]);

  return (
    <div className='students_wrapper' style={{overflow: isMobile ? "hidden" : "scroll"}}>
        <Header />
        <Menu students={lektors} checkNewStudent={checkNewLector} setCheckNewStud={setCheckLektor} setAddingButtonClick={setAddingButtonClick} isMobile={isMobile}/>
        {
          localState.user_info || addingButtonClick ?  <StudentsDashboardConnect students={lektors} setCheckNewStud={setCheckLektor} addingButtonClick={addingButtonClick} isMobile={isMobile}/> : <Placeholder addingButtonClick={addingButtonClick} setAddingButtonClick={setAddingButtonClick} isMobile={isMobile}/>
        }
    </div>
  )
}

export const LektorsConnect = connect((state) => ({
    tutors: state.promise.tutors,
    deleteAction: state.promise.deleteTutor?.payload,
    registerAction: state.promise.regTutors?.payload
  }))(Lektors);
import React from 'react'
import LandingHeader, { Bottom, Cennik, Contacts, Map, Messages, NashiZalety, Nauka, Price, SchoolComponent } from '../components/LandingHeader'
import "../css/landing.css"


const Landing = () => {
  return (
    <div style={{position: "relative"}} className="lad">
        <LandingHeader/>
        <SchoolComponent />
        <Nauka />
        <NashiZalety/>
        <Cennik/>
        <Price/>
        <Map/>
        <Contacts/>
        <Bottom/>
    </div>
  )
}

export default Landing
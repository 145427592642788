import React from 'react'
import { useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { actionUserInfo } from '../actions/actions';
import Header from '../components/Header';
import { store } from '../store/store';
import "../css/profil.css"

import legit from '../images/legitimacja.svg'
import { useState } from 'react';
import { Document, Page, Text, View, StyleSheet , Image , Font} from '@react-pdf/renderer';
import { useRef } from 'react';

import { PDFViewer } from '@react-pdf/renderer';
import { connect } from 'react-redux';

import source from "../fonts/gilroy/Gilroy-Bold.ttf"
import source1 from "../fonts/gilroy/Gilroy-Medium.ttf"

Font.register({ family: 'Gilroy-Bold', src: source });
Font.register({ family: 'Gilroy-Medium', src: source1 });


const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
    color: "#077549"
  },
  backgroundImage: {
    flex: 1,
    width: '100%',
    height: '100%',
    resizeMode: 'cover', // or 'stretch' or 'contain'
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
  },
  view: {
    position: 'relative',
    flex: 1,
    marginTop: 110,
  },
  section: {
    margin: 0,
    marginRight: 300,
    padding: 0,
    fontSize: 20,
    borderRadius: 10,
    textAlign: 'center',
    display: "flex",
    flexDirection: "row",
    fontFamily: "Gilroy-Bold",
    flexWrap: "nowrap",
    left: "150px"
  },
  section1 : {
    marginTop: 20,
    marginRight: 300,
    padding: 0,
    fontSize: 20,
    borderRadius: 10,
    textAlign: 'center',
    display: "flex",
    flexDirection: "row",
    fontFamily: "Gilroy-Bold",
    flexWrap: "nowrap",
    left: "150px"
  },
  text: {
    position: 'absolute',
  },
  logo: {
      width: 300,
      height: 100, // Укажите желаемую высоту логотипа
      marginBottom: 10,
  },
  span: {
    fontSize: "14px",
    fontWeight: "bold", // Use string value like 'bold'
    fontFamily: "Gilroy-Medium", // Add fallback font
    color: "#5f8980",
  },
  h1: {
    fontSize: "14px",
    fontWeight: "900",
    fontFamily: "Gilroy-Bold",
    width: "200px",
    textAlign: "left",
    paddingLeft: "10px",
    color: "#113027"
  },
  surname: {
      marginLeft: "-70px",
      marginTop: "2px",
      fontSize: "14px",
      fontWeight: "900",
      fontFamily: "Gilroy-Bold" 
  },
  nat: {
    fontSize: "14px",
    fontWeight: "900",
    fontFamily: "Gilroy-Bold" 
  },
  field: {
    marginLeft: "-15px",
    marginTop: "2px",
    fontSize: "14px",
    fontWeight: "900",
    fontFamily: "Gilroy-Bold" 
  },
  mode: {
    marginLeft: "-40px",
    marginTop: "2px",
    fontSize: "14px",
    fontWeight: "900",
    fontFamily: "Gilroy-Bold" 
  },
  createdAt: {
    marginLeft: "70px",
    marginTop: "17px",
    fontSize: "14px",
    fontWeight: "900",
    fontFamily: "Gilroy-Bold" 
  },
  chas: {
    marginLeft: "40px",
    marginTop: "0px",
    fontSize: "14px",
    fontWeight: "900",
    fontFamily: "Gilroy-Bold" 
  }
});


export const PDFGenerator = () => {
  const logoPath = require('../images/img1.png');
  const backgroundImagePath = require('../images/img2.png');

  const location = useLocation();
  const {
    first_name,
    last_name,
    date_of_birth,
    nationality,
    start_date,
    id_validity_date,
    passport_number,
    field_of_study,
    study_mode,
    createdAt,
    chas
  } = location.state;

  const name = "Imię"

  return (
    <PDFViewer style={{ width: '100%', height: '100vh' }}>
      <Document>
        <Page orientation="portrait" size={[597, 410]} style={styles.page}>
          <Image src={backgroundImagePath} style={styles.backgroundImage} />
          <View style={styles.container}>
            <View style={styles.view}>
              <View style={styles.section}>
                <Text style={styles.span}>Imię:</Text>
                <Text style={styles.h1}> {`${first_name}`}</Text>
              </View>
              <View style={styles.section}>
                <Text style={styles.span}>Nazwisko:</Text>
                <Text style={styles.h1}> {`${last_name}`}</Text>
              </View>
              <View style={styles.section}>
                <Text style={styles.span}>Obywatelstwo:</Text>
                <Text style={styles.h1}> {`${nationality}`}</Text>
              </View>
              <View style={styles.section}>
                <Text style={styles.span}>Kierunek studiów:</Text>
                <Text style={styles.h1}> {`${field_of_study}`}</Text>
              </View>
              <View style={styles.section}>
                <Text style={styles.span}>Tryb studiów:</Text>
                <Text style={styles.h1}> {`${study_mode}`}</Text>
              </View>
              <View style={styles.section1}>
                <Text style={styles.span}>Data rozpoczęcia studiów:</Text>
                <Text style={styles.h1}> {`${start_date}`}</Text>
              </View>
              <View style={styles.section}>
                <Text style={styles.span}>Planowana data ukończenia studiów:</Text>
                <Text style={styles.h1}> {`${id_validity_date}`}</Text>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

  
const Profil = ({
  email,
  first_name,
  last_name,
  date_of_birth,
  nationality,
  id_validity_date,
  passport_number,
  field_of_study,
  study_mode,
  start_date
}) => {


  function formatDate(dateString) {
    const dateObject = new Date(dateString);
  
    const year = dateObject.getUTCFullYear();
    const month = (dateObject.getUTCMonth() + 1).toString().padStart(2, '0');
    const day = dateObject.getUTCDate().toString().padStart(2, '0');
  
    return `${year}/${month}/${day}`;
  }

  function calculateDaysDifference(startDateString, endDateString) {
    const startDate = new Date(startDateString);
    const endDate = new Date(endDateString);
  
    // Calculate the difference in milliseconds
    const timeDifference = endDate - startDate;
  
    // Convert the difference to days
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  
    // Convert the difference to weeks
    const weeksDifference = Math.floor(daysDifference / 7);
  
    return weeksDifference;
  }

  
  return (
    <>
      <div className='profil'>
        <Header />
        <div className='student-info'>
          <div className='student-info-left'>
            <div className="info-item">
              <div className="info-column">
              <div className="info-column">
                <h4>First Name</h4><span>{first_name}</span>
                <h4>Last Name</h4><span>{last_name}</span>
                <h4>Email</h4><span>{email}</span>
                <h4>Date of Birth</h4><span>{date_of_birth}</span>
                <h4>Nationality</h4><span>{nationality}</span>
              </div>
              <div className="info-column">
                <h4>Start Date</h4><span>{start_date}</span>
                <h4>Date of graduation</h4><span>{id_validity_date}</span>
                <h4>Passport Number</h4><span>{passport_number}</span>
                <h4>Field of Study</h4><span>{field_of_study}</span>
                <h4>Study Mode</h4><span>{study_mode}</span>
              </div>
              </div>
            </div>
          </div>
          <div className='student-info-right'>
             <Link
                  to="/legit" state={{
                    first_name: first_name,
                    last_name: last_name,
                    date_of_birth: date_of_birth,
                    nationality: nationality,
                    start_date: start_date,
                    id_validity_date: id_validity_date,
                    passport_number: passport_number,
                    field_of_study: field_of_study,
                    study_mode: study_mode,
                  }} style={{textDecoration: "none"}}>
                          <button className='student-info-right-button'><img src={legit} style={{width: "25px" , height: "25px"}}/>Pobierz legytymację</button>
                </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export const ProfilConnect = connect((state) => ({
  email: state.promise.userInfo?.payload?.email,
  first_name: state.promise.userInfo?.payload?.first_name,
  last_name: state.promise.userInfo?.payload?.last_name,
  date_of_birth: state.promise.userInfo?.payload?.date_of_birth,
  nationality: state.promise.userInfo?.payload?.nationality,
  id_validity_date: state.promise.userInfo?.payload?.id_validity_date,
  passport_number: state.promise.userInfo?.payload?.passport_number,
  field_of_study: state.promise.userInfo?.payload?.field_of_study,
  study_mode: state.promise.userInfo?.payload?.study_mode,
  start_date: state.promise.userInfo?.payload?.start_date
}))(Profil);



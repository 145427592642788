import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { actionLogin, actionRegister, actionRememberPassword, actionUserInfo } from '../actions/actions';
import "../css/login.css"
import { store } from '../store/store';
import login_image from '../images/loginpage.jpg'

import logo from '../images/imgLanding/Frame 4-2.svg';
import logoGreen from '../images/imgLanding/Frame 4-3.svg';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import { actionCheckRole } from '../store/actionsReducer';

const Login = ({role , checkRole , setCheckRole}) => {
  const navigation = useNavigate()

  const [rememberPass , setRememberPass] = useState(false)

  const [formData, setFormData] = useState({
    email: '',
    password: '',
    error: '',
  });

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

      const [isUpsideDown, setIsUpsideDown] = useState(false);

    useEffect(() => {
      const handleResize = () => {
        // Проверяем высоту и ширину окна для определения ориентации
        const isUpsideDownOrientation = window.innerHeight > window.innerWidth;
  
        // Обновляем состояние
        setIsUpsideDown(isUpsideDownOrientation);
  
        // Выводим в консоль true, если ориентация перевернута
        if (isUpsideDownOrientation) {
          console.log(true);
        }
      };
  
      // Добавляем обработчик события изменения размеров окна
      window.addEventListener('resize', handleResize);
  
      // Вызываем обработчик в первый раз, чтобы установить начальное значение
      handleResize();
  
      // Убираем обработчик события при размонтировании компонента
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []); 

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
      if(role){
        store.dispatch(actionCheckRole(role?.role))
        if(role?.role === "user"){
             setCheckRole("user")
            navigation(`/calendar`)
        }
        if(role?.role === "tutor"){
            setCheckRole("tutor")
            navigation(`/calendar`)
        }
        if(role?.role === "admin"){
            setCheckRole("admin")
            navigation(`/kursy`)
        }
      }
  }, [role])
  
const handleLogin = async () => {
  try {
    const data = {
      email: formData.email,
      password: formData.password,
    };

    let res = await store.dispatch(actionLogin(data));

    if(!res){
      setFormData({
        ...formData,
        error: "Invalid login or password"
      });
    }

    if(res?.status === "success"){
      await store.dispatch(actionUserInfo(res.email))
      // navigation(`/:${res.userId}/`)
    }

  } catch (error) {
    console.log(error)
  }
  
};

const handleRemember = async () => {
  try {
    const data = {
      email: formData.email,
    };

    let res = await store.dispatch(actionRememberPassword(data))

    if(formData.email == []){
      setFormData({
        ...formData,
        error: "Please type email"
      });
    }

    if(formData.email){
      setFormData({
        ...formData,
        error: "Password recovery"
      });
    }

  } catch (error) {
    console.log(error)
  }
  
};

  return (
    <div className="login-container">
      {
        windowWidth < 1026 ? 
        (
          <>
        <div className='left'>
            <div className='logo-login'>
                    <img src={logoGreen}/>
            </div>
        </div>
        <div className='right'>
            <div className="login-form">
              <div className='label'>
                {
                  !isUpsideDown ? (
                    <>
                    </>
                  ) : (
                    <>
                      <h1>Log In</h1>
                      <span>Żeby zobaczyć swój profil</span>
                    </>
                  )
                }
              </div>
              <div className='login-process'>
              
                  <label htmlFor="username">Email</label>
                  <input
                    type="text"
                    id="email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                  {!rememberPass ? 
                  <>
                    <label htmlFor="password">Haslo</label>
                    <input
                      type="password"
                      id="password"
                      value={formData.password}
                      onChange={handleInputChange}
                    />
                  </>
                  : []
                  }
                  {
                    !rememberPass ? 
                    <div style={{width: "100%", display: "flex" , justifyContent: "flex-end", marginTop: "20px" , cursor: "pointer"}} onClick={() => setRememberPass(true)}>Forgot your password?</div>
                    :
                    []
                  }

                  <button onClick={ rememberPass ? handleRemember : handleLogin}>{rememberPass ? "Wyślij hasło" : "Wejść"}</button>

                  {formData.error && <p className="error-message">{formData.error}</p>}
              </div>
            </div>
        </div>
          </>
        ) : (
          <>
        <div className='left'>
            {/* <span>Welcome to laguage school</span> */}
            <div className='logo-login'>
                <img src={logo}/>
            </div>
            <img src={login_image} alt="" />
        </div>
        <div className='right'>
            <div className="login-form">
              <div className='label'>
                <h1>Log In</h1>
                <span>Żeby zobaczyć swój profil</span>
              </div>
              <div className='login-process'>
              
                  <label htmlFor="username">Email</label>
                  <input
                    type="text"
                    id="email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />

                  {!rememberPass ? 
                  <>
                    <label htmlFor="password">Haslo</label>
                    <input
                      type="password"
                      id="password"
                      value={formData.password}
                      onChange={handleInputChange}
                    />
                  </>
                  : []
                  }
                  {
                    !rememberPass ? 
                    <div style={{width: "100%", display: "flex" , justifyContent: "flex-end", marginTop: "20px"}} onClick={() => setRememberPass(true)}>Forgot your password?</div>
                    :
                    []
                  }

                  <button onClick={ rememberPass ? handleRemember : handleLogin}>{rememberPass ? "Wyślij hasło" : "Wejść"}</button>

                  {formData.error && <p className="error-message">{formData.error}</p>}
              </div>
            </div>
        </div>
          
          </>
        )
      }
       
    </div>
  )
}

export const LoginConnect = connect((state) => ({
  role: state.promise.userInfo?.payload,
}))(Login);

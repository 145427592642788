import {createStore, combineReducers, applyMiddleware} from 'redux';
import { thunk } from 'redux-thunk';
import actionsReducer from './actionsReducer';

import { promiseReducer } from './promiseReducer';

export const store = createStore(combineReducers({
    promise: promiseReducer,
    actions: actionsReducer
}),applyMiddleware(thunk))

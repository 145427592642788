import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

const UserInfo = ({ userInfo }) => {
  const [userDetails, setUserDetails] = useState({
    email: '',
    name: '',
    role: '',
    userId: '',
    group: '',
  });

  useEffect(() => {
    // Update state when userInfo changes
    if (userInfo) {
      setUserDetails({
        email: userInfo.email || '',
        name: userInfo.name || '',
        role: userInfo.role || '',
        userId: userInfo.userId || '',
        group: userInfo.group || '',
      });
    }
  }, [userInfo]);

  return (
    <div>
      <p>Email: {userDetails.email}</p>
      <p>Name: {userDetails.name}</p>
      <p>Role: {userDetails.role}</p>
      <p>User ID: {userDetails.userId}</p>
      <p>Group: {userDetails.group}</p>
      {/* Render other user-specific information */}
    </div>
  );
};

export const UserInfoConnect = connect((state) => ({
  userInfo: state.promise.userInfo.payload,
}))(UserInfo);




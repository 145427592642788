import React, { useState } from "react";
import { useEffect } from "react";
import {promiseReducer , getData, pushData } from "../store/promiseReducer"
import { store } from "../store/store"
import { connect } from "react-redux";


const serverLink = "https://www.ii-fl.space"

// ! PROMISE
const actionPending = (name) => ({
    type: "PROMISE",
    status: "PENDING",
    name,
  });

const actionFulfilled = (name, payload) => ({
    type: "PROMISE",
    status: "FULFILLED",
    name,
    payload,
  });

const actionRejected = (name, error) => ({
    type: "PROMISE",
    status: "REJECTED",
    name,
    error,
  });

export  const actionPromise = (name, promise) => async (dispatch) => {
    try {
        dispatch(actionPending(name));
        let payload = await promise;
        dispatch(actionFulfilled(name, payload));
        return payload;
    } catch (e) {
        dispatch(actionRejected(name, e));
    }
  };


// !LOGIN

export const actionLogin = (data) => async (dispatch) => {
    try {
      const response = await dispatch(
        actionPromise('login', pushData(`${serverLink}/login`, data))
      );
  
      // If the response is OK, handle success
      return response;
  
    } catch (error) {
      return { error: 'Login failed', message: error.message || 'An error occurred during login' };
    }
  };


export const actionRememberPassword = (data) => async (dispatch) => {
    try {
      const response = await dispatch(
        actionPromise('rememberPassword', pushData(`${serverLink}/remember`, data))
      );
  
      return response;
  
    } catch (error) {
      return { error: 'Remember failed', message: error.message || 'An error occurred during remember' };
    }
  };


// !REGISTER

export const actionRegister = (data) =>
        async dispatch => {
            await dispatch(actionPromise("register" , pushData(`${serverLink}/register` , data)))
        }

export const actionRegisterTutors = (data) =>
        async dispatch => {
          await dispatch(actionPromise("regTutors" , pushData(`${serverLink}/regTutors` , data)))
        }

export const actionRegisterGroup = (data) =>
        async dispatch => {
          await dispatch(actionPromise("regGroups" , pushData(`${serverLink}/regGroups` , data)))
        }

// ! INFO ABOUT USER

export const actionUserInfo = (email) => 
        async dispatch => {
          await dispatch(actionPromise("userInfo" , getData(`${serverLink}/user/${email}`)))
        }

// ! INFO ABOUT USERS

export const actionAllUsers = () =>
        async dispatch => {
          await dispatch(actionPromise("users" , getData(`${serverLink}/users`)))
        }

// ! INFO ABOUT TUTORS

export const actionAllTutors = () =>
        async dispatch => {
          await dispatch(actionPromise("tutors" , getData(`${serverLink}/tutors`)))
        }

// ! INFO ABOUT KURSES

export const actionAllKurses = () =>
        async dispatch => {
          await dispatch(actionPromise("kursy" , getData(`${serverLink}/kursy`)))
        }

// ! INFO ABOUT ONE GROUP

export const actionGroupInfo = (group_id) => 
        async dispatch => {
          await dispatch(actionPromise("groupInfo" , getData(`${serverLink}/kursy/${group_id}`)))
        }

// ! ACTION ADD STUDENT TO COURSE
export const actionAddStudentToKurs = (data) =>
        async dispatch => {
          await dispatch(actionPromise("addStudentToKurs" , pushData(`${serverLink}/addUserToGroup` , data)))
        }


// ! ACTION SENT EMAIL TO CONTACT WITH
export const actionLead = (data) =>
        async dispatch => {
          await dispatch(actionPromise("actionLead" , pushData(`${serverLink}/sendEmail` , data)))
        }

// ! ACTION ADD STUDENT TO COURSE
export const actionAddTutorToKurs = (data) =>
        async dispatch => {
          await dispatch(actionPromise("addTutorToKurs" , pushData(`${serverLink}/addTutorToGroup`, data)))
        }

// ! ACTION ADD INDIVIDUAL EVENT
export const actionAddIndEvent= (data) =>
        async dispatch => {
          await dispatch(actionPromise("addIndEvent" , pushData(`${serverLink}/createIndividualEvent` , data)))
        }

// ! ACTION ADD EVENT
export const actionAddEvent= (data) =>
        async dispatch => {
          await dispatch(actionPromise("addEvent" , pushData(`${serverLink}/createEvent` , data)))
        }

// ! ACTION CHANGE EVENT
export const actionChangeEvent= (data) =>
        async dispatch => {
          await dispatch(actionPromise("changeEvent" , pushData(`${serverLink}/updateEvent` , data)))
        }

// ! ACTION ADD EVENT TO GROUP
export const actionAddEventToGroup = (data) =>
        async dispatch => {
          await dispatch(actionPromise("addEventGroup" , pushData(`${serverLink}/addEventToGroup` , data)))
        }

// ! ACTION GROUPS WHERE TUTOR GIVE KNOLEDGE
export const actionGroupWithTutors = (data) =>
        async dispatch => {
          await dispatch(actionPromise("groupsWhereTutor" , getData(`${serverLink}/tutorLearnInGroups/${data}`)))
        }

        // studentsEventsInGroups
// ! ACTION GROUPS WHERE STUDENT TAKE
export const actionGroupWithStudentsEvents = (data) =>
        async dispatch => {
          await dispatch(actionPromise("groupsWhereStudent" , getData(`${serverLink}/studentsEventsInGroups/${data}`)))
        }


// ! DELETE EVENT

export const actionDeleteEvent = (data) =>
        async dispatch => {
          await dispatch(actionPromise("deleteEvent" , pushData(`${serverLink}/deleteEvent` , data)))
        }

// ! DELETE GROUP

export const actionDeleteGroup = (data) =>
        async dispatch => {
          await dispatch(actionPromise("deleteGroup" , pushData(`${serverLink}/deleteGroup` , data)))
        }

// ! REMOVE STUDENT FROM KURS

export const actionRemoveStudentFromKurs = (data) =>
        async dispatch => {
          await dispatch(actionPromise("removeStudentFromKurs" , pushData(`${serverLink}/deleteStudentFromGroup` , data)))
        }

// ! DELETE STUDENT

export const actionDeleteStudent = (data) =>
        async dispatch => {
          await dispatch(actionPromise("deleteStudent" , pushData(`${serverLink}/deleteStudent` , data)))
        }

// ! DELETE TUTOR

export const actionDeleteTutor = (data) =>
        async dispatch => {
          await dispatch(actionPromise("deleteTutor" , pushData(`${serverLink}/deleteTutor` , data)))
        }

           